import React, { useState, useEffect } from "react";
import image1 from "assets/images/hustle/corner.png";
import icon1 from "assets/images/icons/work.png";
import ReUpForm from "./ReupForm";
import { useSelector } from "react-redux";
import "../../index.css"

import { anchorGetBalance } from "BlockchainInteractionWax";

const Top = ({ ual }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [workResource, setWorkResource] = useState(0);

  const store = useSelector((state) => state);

  const fetchData = async () => {
    if (ual.activeUser) {
      const values = await anchorGetBalance(ual);
      setWorkResource(parseFloat(values[3]).toFixed(3));
    }
  };

  useEffect(() => {
    fetchData();
  }, [store, ual]);

  return (
    <>
      <div className="flex flex-col items-center font-Montserrat">
        {/* <div className="img">
          <img width="300" height="200" src={image1} alt="Corner Logo" />
        </div> */}
        <div className="flex flex-col items-center font-Montserrat">
          <p className="mb-1 text-md font-bold text-olive">Remaining Work</p>
          <div className="flex items-center gap-x-2">
            <div className="w-16">
              <img src={icon1} alt="" className="object- h-full w-full" />
            </div>
            <p className="mb-1 text-xl font-bold text-olive">
              {workResource}
            </p>
            <div className="w-16">
              <img src={icon1} alt="" className="object- h-full w-full" />
            </div>
          </div>
        </div>
        <div className='buttonContainer '  >
          <button className="w-32" onClick={() => setIsOpen(true)}>Re-up</button>
        </div>
      </div>
      <ReUpForm setIsOpen={setIsOpen} isOpen={isOpen} ual={ual} />
    </>
  );
};

export default Top;

