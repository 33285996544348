import React from "react";
import { FaTimes } from "react-icons/fa";
import { v4 as uuid } from "uuid";
import icon3 from "assets/images/icons/clean-cash.png";
import icon4 from "assets/images/icons/hst-final.png";
import upgrade from "assets/images/icons/slot.png"
import dirty_cash from "assets/images/icons/hustler-clean-cash-token.png";
import icon1 from "assets/images/icons/slot.png";
import Modal from "components/shared/Modal";
import { useDispatch } from "react-redux";

import { UnlockArea, refreshAll } from "BlockchainInteractionWax";

const data = [
  {
    id: uuid(),
    icon: dirty_cash,
    cost: "114000",
    classes: "w-10 mx-auto mt-2 mb-2",
  },
  {
    id: uuid(),
    icon: icon3,
    cost: "12000",
    classes: "w-16",
  },
  {
    id: uuid(),
    icon: icon4,
    cost: "15000",
    classes: "w-16",
  }
];


const UnlockForm = ({ isOpen, setIsOpen, ual }) => {
  const dispatch = useDispatch();
  
  const unlockArea = async (ual) => {
    const result = await UnlockArea(ual, 2); 
    if(result === true)
    {    
      handleUnlock();
      setIsOpen(false);
      await refreshAll(dispatch);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
    >
      <div
        className="w-full rounded-xl py-4 px-6"
        style={{ backgroundImage: "radial-gradient(#292929, #000000)", border: "6px outset rgba(165, 165, 165, 0.4)" }}
      >
        <>
          <div className="mb-2 flex items-center justify-between">
            <div />
            <div />
            <h2 className="px-5 py-2 text-center font-Montserrat text-2xl font-bold text-white">
              Cost
            </h2>
            <FaTimes
              className="block text-2xl text-white hover:cursor-pointer"
              onClick={() => setIsOpen(false)}
            />
          </div>

          <div className="flex justify-center">
            <div>
              <div
                className="flex items-center justify-center gap-x-3"
                key={Math.random()}
              >
                <div className="w-10">
                  <img
                    src={icon1}
                    className="h-full w-full object-contain"
                    alt="Wallet Icon"
                  />
                </div>
                <p className="font-bold text-white">1500</p>
              </div>
              {data.map((item) => (
                <div className="flex items-center gap-x-3" key={Math.random()}>
                  <div className={item.classes}>
                    <img
                      src={item.icon}
                      className="h-full w-full object-contain"
                      alt="Token Icon"
                    />
                  </div>
                  <p className="font-bold text-white">{item.cost}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-5 flex justify-center">
            <button
              className="my-5 flex flex-col items-center justify-center rounded-lg border border-[#2F2F2E] py-3 px-3 text-lime-400 hover:bg-[#252525] w-40"
              onClick={() => {                
                unlockArea(ual);                
              }}
            >
              Unlock
            </button>
          </div>
        </>
      </div>
    </Modal>
  );
};

let handleUnlock = () => {
  document.querySelectorAll(".locked-area").forEach((entries) => {
    entries.classList.remove("locked");
    entries.classList.add("unlocked");
  });
};

export default UnlockForm;
