import React, { useEffect, useState, useCallback } from "react";
import { FaTimes } from "react-icons/fa";
import Modal from "components/shared/Modal";
import Stats from "./Stats";
import UpgradeForm from "./UpgradeForm";
import SkipUpgradeForm from "./SkipUpgradeForm";
import { useDispatch, useSelector } from "react-redux";

import { anchorGetNftPopUpInfoLocation, claimRewardsNftAll, refreshAll, payBailAllNft, anchorUnstakeNftLocations, anchorGetNftUpgradeCost, nftOutOfJail, anchorGetNftPopUpJailUpgradeInfo } from "BlockchainInteractionWax";


const ModalMenu = ({ isOpen, setIsOpen, ual, asset_id }) => {
  const [isUpGradeOpen, setIsUpgradeOpen] = useState(false);
  const [isSkipUpGradeOpen, setSkipIsUpgradeOpen] = useState(false);
  const [isStatsOpen, setIsStatsOpen] = useState(false);
  const [isUnstakeOpen, setIsUnstakeOpen] = useState(false);

  const [name, setName] = useState("");
  const [level, setLevel] = useState(0);
  const [rarity, setRarity] = useState(0);
  const [edition, setEdition] = useState(0);
  const [type, setType] = useState(0);
  const [power, setPower] = useState(0);
  const [ambition, setAmbition] = useState(0);
  const [strength, setStrength] = useState(0);
  const [talent, setTalent] = useState("");
  const [max_level, setMaxLevel] = useState(0);
  const [clean_cash_upgrade, setCleanCashUpgrade] = useState(0.0);
  const [dirty_cash_upgrade, setDirtyCashUpgrade] = useState(0.0);
  const [hustler_token_upgrade, setHustlerTokenUpgrade] = useState(0.0);
  const [upgrade_token_upgrade, setUpgradeTokenUpgrade] = useState(0.0);

  const [clean_cash_upgrade_skip, setCleanCashUpgradeSkip] = useState(0.0);
  const [dirty_cash_upgrade_skip, setDirtyCashUpgradeSkip] = useState(0.0);
  const [hustler_token_upgrade_skip, setHustlerTokenUpgradeSkip] = useState(0.0);

  const [cooldown, setCooldown] = useState(0.0);
  const [inJail, setInJail] = useState(0.0);
  const [jailUntil, setJailUntil] = useState(0.0);

  const store = useSelector((state) => state);
  const dispatch = useDispatch();

  const fetchData = async () => {
    const value = await anchorGetNftPopUpInfoLocation(ual, asset_id);
    setName(value[0].name)
    setLevel(value[0].level)
    setRarity(value[0].rarity)
    setEdition(value[0].edition)
    setType(value[0].type)
    setPower(value[0].power)
    setAmbition(value[0].ambition)
    setStrength(value[0].strength)
    setTalent(value[0].talent)
    setMaxLevel(value[0].max_level)
    const value1 = await anchorGetNftUpgradeCost(ual, asset_id)
    setCleanCashUpgrade(value1[0].cleanCash)
    setDirtyCashUpgrade(value1[0].dirtyCash)
    setHustlerTokenUpgrade(value1[0].hustlerToken)
    setUpgradeTokenUpgrade(value1[0].upgradeToken)
    setCleanCashUpgradeSkip(value1[0].cleanCashSkip)
    setDirtyCashUpgradeSkip(value1[0].dirtyCashSkip)
    setHustlerTokenUpgradeSkip(value1[0].hustlerTokenSkip)
    const value2 = await anchorGetNftPopUpJailUpgradeInfo(ual, asset_id, 1)
    setCooldown(value2[0].cooldown)
    setInJail(value2[0].isJailed)
    setJailUntil(value2[0].jailUntil)
  };

  async function claimReward() {
    await claimRewardsNftAll(ual, asset_id, 1, 0)
    await new Promise(resolve => setTimeout(resolve, 500));
    await refreshAll(dispatch)
    setIsOpen(false)
  };

  async function getOutOfJail() {
    await nftOutOfJail(ual, 0);
    await new Promise(resolve => setTimeout(resolve, 500));
    await refreshAll(dispatch)
    setIsOpen(false)
  };


  async function payBail() {
    await payBailAllNft(ual, asset_id, 1, 0)
    await new Promise(resolve => setTimeout(resolve, 500));
    await refreshAll(dispatch)
    setIsOpen(false)
  };

  async function unstake() {
    console.log(ual)
    await anchorUnstakeNftLocations(ual, asset_id, 1, 0)
    await new Promise(resolve => setTimeout(resolve, 500));
    await refreshAll(dispatch)
    setIsOpen(false)
  };

  async function unstakeAll() {
    console.log(ual)
    await anchorUnstakeNftLocations(ual, 0, 1, 1)
    await new Promise(resolve => setTimeout(resolve, 500));
    await refreshAll(dispatch)
    setIsOpen(false)
  };


  useEffect(() => {
    fetchData();
  }, [store, ual]);

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={(event) => { event.stopPropagation(); setIsOpen(false) }}>
        <div
          className="w-full bg-[#1a1b1f] border rounded-xl border-[#252525]  py-4 px-6"
          style={{ backgroundImage: "radial-gradient(#292929, #000000)", border: "6px outset rgba(165, 165, 165, 0.4)" }}
        >
          <div>
            <div className="mb-2 flex items-center justify-between">
              <div />
              <h2 className="px-5 py-2 text-center font-Montserrat text-2xl font-bold text-olive">
              {name} {level} / {max_level}
              </h2>
              <FaTimes
                className="block text-2xl text-olive hover:cursor-pointer"
                onClick={(event) => {
                  event.stopPropagation(); // Stop the event from propagating to parent elements
                  setIsOpen(false);
                }}
              />
            </div>


            <div className="text-center">
              {inJail < 1 && (
                <div className="my-5">
                  <div className='buttonContainer my-5'  >
                    <button className="w-full px-4 py-1 " onClick={(event) => {
                      event.stopPropagation(); // Stop the event from propagating to parent elements
                      claimReward()
                    }}>Collect Reward</button>
                  </div>
                  {/* <div className="my-5 flex flex-col items-center justify-center rounded-lg border border-[#2F2F2E] py-3 px-3 text-lime-400 hover:bg-[#252525] rounded-md"
                 onClick={(event) => {
                  event.stopPropagation(); // Stop the event from propagating to parent elements
                  claimReward()                  
                }}>Collect Reward</div> */}
                </div>
              )}
              {cooldown <= 0 && inJail < 1 && (
                <div className="my-5">
                  {/* <div
                    className="my-5 flex flex-col items-center justify-center rounded-lg border border-[#2F2F2E] py-3 px-3 text-lime-400 hover:bg-[#252525] rounded-md"
                    onClick={() => setIsUpgradeOpen(true)}
                  >
                    Upgrade
                  </div> */}
                  <div className='buttonContainer my-5'  >
                    <button className="w-full px-4 py-1 " onClick={() => setIsUpgradeOpen(true)}>Upgrade</button>
                  </div>
                </div>
              )}

              {cooldown > 0 && (
                <div className="my-5">
                  {/* <div
                    className="my-5 flex flex-col items-center justify-center rounded-lg border border-[#2F2F2E] py-3 px-3 text-lime-400 hover:bg-[#252525] rounded-md"
                    onClick={() => setSkipIsUpgradeOpen(true)}
                  >
                    Skip Upgrade Cooldown
                  </div> */}
                  <div className='buttonContainer my-5'  >
                    <button className="w-full px-4 py-1 " onClick={() => setSkipIsUpgradeOpen(true)}>Skip Upgrade Cooldown</button>
                  </div>
                </div>
              )}

              {jailUntil > 0 && (
                <div className="my-5">
                  {/* <div className="my-5 flex flex-col items-center justify-center rounded-lg border border-[#2F2F2E] py-3 px-3 text-lime-400 hover:bg-[#252525] rounded-md"
                    onClick={(event) => {
                      event.stopPropagation(); // Stop the event from propagating to parent elements
                      payBail()
                    }}>Pay bail</div> */}
                  <div className='buttonContainer my-5'  >
                    <button className="w-full px-4 py-1 " onClick={(event) => {
                      event.stopPropagation(); // Stop the event from propagating to parent elements
                      payBail()
                    }}>Pay bail</button>
                  </div>
                </div>
              )}
              {jailUntil <= 0 && inJail > 0 && (
                <div className="my-5">
                  {/* <div className="my-5 flex flex-col items-center justify-center rounded-lg border border-[#2F2F2E] py-3 px-3 text-lime-400 hover:bg-[#252525] rounded-md"
                    onClick={(event) => {
                      event.stopPropagation(); // Stop the event from propagating to parent elements
                      getOutOfJail()
                    }}>Get out of jail</div> */}
                  <div className='buttonContainer my-5'  >
                    <button className="w-full px-4 py-1 " onClick={(event) => {
                      event.stopPropagation(); // Stop the event from propagating to parent elements
                      getOutOfJail()
                    }}>Get out of jail</button>
                  </div>
                </div>
              )}
              {/* <div className="my-5" onClick={() => setIsStatsOpen(true)}>
                <div className="my-5 flex flex-col items-center justify-center rounded-lg border border-[#2F2F2E] py-3 px-3 text-lime-400 hover:bg-[#252525] rounded-md">Stats</div>
              </div> */}
              <div className='buttonContainer my-5'  >
                <button className="w-full px-4 py-1 " onClick={() => setIsStatsOpen(true)}>Stats</button>
              </div>
              {inJail <= 0 && (
                <div className="my-5">
                  {/* <div
                    className="my-5 flex flex-col items-center justify-center rounded-lg border border-[#2F2F2E] py-3 px-3 text-lime-400 hover:bg-[#252525] rounded-md"
                    onClick={(event) => {
                      event.stopPropagation(); // Stop the event from propagating to parent elements
                      unstake()
                    }}>
                    Unstake
                  </div> */}
                  <div className='buttonContainer my-5'  >
                    <button className="w-full px-4 py-1 " onClick={(event) => {
                      event.stopPropagation(); // Stop the event from propagating to parent elements
                      unstake()
                    }}>Unstake</button>
                  </div>
                </div>
              )}
              <div className="my-5">
                {/* <div
                  className="my-5 flex flex-col items-center justify-center rounded-lg border border-[#2F2F2E] py-3 px-3 text-lime-400 hover:bg-[#252525] rounded-md"
                  onClick={(event) => {
                    event.stopPropagation(); // Stop the event from propagating to parent elements
                    unstakeAll()
                  }}>
                  Unstake All
                </div> */}
                <div className='buttonContainer my-5'  >
                  <button className="w-full px-4 py-1 " onClick={(event) => {
                    event.stopPropagation(); // Stop the event from propagating to parent elements
                    unstakeAll()
                  }}>Unstake All</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <UpgradeForm
        isOpen={isUpGradeOpen}
        setIsOpen={setIsUpgradeOpen}
        ual={ual}
        clean_cash_upgrade={clean_cash_upgrade}
        dirty_cash_upgrade={dirty_cash_upgrade}
        hustler_token_upgrade={hustler_token_upgrade}
        upgrade_token_upgrade={upgrade_token_upgrade}
        asset_id={asset_id}
      />

      <SkipUpgradeForm
        isOpen={isSkipUpGradeOpen}
        setIsOpen={setSkipIsUpgradeOpen}
        ual={ual}
        clean_cash_upgrade_skip={clean_cash_upgrade_skip}
        dirty_cash_upgrade_skip={dirty_cash_upgrade_skip}
        hustler_token_upgrade_skip={hustler_token_upgrade_skip}
        asset_id={asset_id}
      />

      <Stats isOpen={isStatsOpen} setIsOpen={setIsStatsOpen} name={name} level={level} rarity={rarity} edition={edition} power={power} ambition={ambition} strength={strength} talent={talent}/>
    </>
  );
};

export default ModalMenu;
