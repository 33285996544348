import React from "react";
import { FaTimes } from "react-icons/fa";
import icon3 from "assets/images/icons/clean-cash.png";
import icon4 from "assets/images/icons/hst-final.png";
import icon2 from "assets/images/icons/hustler-clean-cash-token.png";
import icon1 from "assets/images/icons/slot.png";
import Modal from "../../../../shared/Modal";
import { useDispatch, useSelector } from "react-redux";

import { v4 as uuid } from "uuid";
import { refreshAll, upgradeNftLocations } from "BlockchainInteractionWax";

const UpgradeForm = ({ isOpen, setIsOpen, ual, clean_cash_upgrade, dirty_cash_upgrade, hustler_token_upgrade, upgrade_token_upgrade, asset_id }) => {

  const data = [
    {
      id: uuid(),
      icon: icon1,
      cost: upgrade_token_upgrade,
      classes: "w-12",
    },
    {
      id: uuid(),
      icon: icon2,
      cost: dirty_cash_upgrade,
      classes: "w-10 mx-auto my-2",
    },
    {
      id: uuid(),
      icon: icon3,
      cost: clean_cash_upgrade,
      classes: "w-16",
    },
    {
      id: uuid(),
      icon: icon4,
      cost: hustler_token_upgrade,
      classes: "w-16",
    },
  ];

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    
    setIsOpen(false);
  };

  async function upgradeNft () {
    await upgradeNftLocations(ual, asset_id, 1)
    await refreshAll(dispatch) 
    setIsOpen(false)
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
    >
      <div
        className="w-full rounded-xl py-4 px-6"
        style={{ backgroundImage: "radial-gradient(#292929, #000000)", border: "6px outset rgba(165, 165, 165, 0.4)" }}
      >
        <form onSubmit={submitHandler}>
          <div className="mb-2 flex items-center justify-between">
            <div />
            <div />
            <h2 className="px-5 py-2 text-center font-Montserrat text-2xl font-bold text-olive">
              Upgrade Character
            </h2>
            <FaTimes
              className="block text-2xl text-olive hover:cursor-pointer"
              onClick={() => {
                setIsOpen(false);
              }}
            />
          </div>

          <div className="flex justify-center">
            <div>
              {data.map((item) => (
                <div className="flex  items-center gap-x-3" key={Math.random()}>
                  <div className={item.classes}>
                    <img
                      src={item.icon}
                      className="h-full w-full object-contain"
                      alt="Token Icon"
                    />
                  </div>
                  <p className="font-bold text-white">{item.cost}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-5 flex justify-center">
          <div className='buttonContainer my-5'  >
              <button className="w-40 px-4 py-1 "  onClick={() => {
              upgradeNft();
              }}>Upgrade</button>
            </div>
            {/* <button className="my-5 flex flex-col items-center justify-center rounded-lg border border-[#2F2F2E] py-3 px-3 text-lime-400 hover:bg-[#252525]" 
            onClick={() => {
              upgradeNft();
              }}>Upgrade</button> */}
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default UpgradeForm;
