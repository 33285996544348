import React, { useEffect, useState, useCallback } from "react";
import { FaPlus } from "react-icons/fa";
import icon1 from "assets/images/icons/solo-g.png";
import DirtyCashTokenIcon from "assets/images/icons/dirty-cash-token.png";
import AddCharacter from "./AddCharacter";
import AddPass from "./AddPass";
import AddSlot from "./AddSlot"
import ModalMenu from "./ModalMenu";
import { useDispatch, useSelector } from "react-redux";

import {
  anchorGetLocationCrewSlots, anchorGetLocationCrew, getUpgradeCostsForOtherCrew, anchorGetLocationNftInfo,
  getTotalJailNftsLocation
} from "BlockchainInteractionWax";

const LeftContent = ({ ual }) => {

  const [currentSlots, setCurrentSlots] = useState(0);
  const [currentUsedSlots, setCurrentUsedSlots] = useState(0);
  const [slots, setSlots] = useState([]);
  const [upgradeSlotsCost, setUpgradeSlotsCost] = useState({
    clean_cash: 0,
    dirty_cash: 0,
    hustler_token: 0,
    slot_number: 0,
    upgrade_token: 0
  });
  const [total_jail, setTotalJail] = useState(0)

  const store = useSelector((state) => state);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPass, setIsOpenPass] = useState(false);

  const fetchData = async () => {
    const value = await anchorGetLocationCrewSlots(ual, 0);
    setCurrentSlots(value);
    const value1 = await anchorGetLocationCrew(ual, 0);
    setCurrentUsedSlots(value1);
    const value3 = await getUpgradeCostsForOtherCrew(ual, value);
    setUpgradeSlotsCost(value3)
    const imageData = await anchorGetLocationNftInfo(ual, 0)
    setSlots(imageData);
    const value4 = await getTotalJailNftsLocation(ual, 0)
    setTotalJail(value4)
  };

  useEffect(() => {
    fetchData();
  }, [store, ual]);

  return (
    <div className="order-2 w-full md:w-1/2 lg:order-1 lg:w-4/12">
      <div className="mx-4 box-border  pb-4 ">
        <div className="mb-2 flex items-center justify-between">
          <div className="ml-4 flex items-center gap-x-2">
            <div className="w-8">
              <img src={icon1} alt="Solo G" className="object- h-full w-full" />
            </div>
            <p className=" text-center text-xl font-bold text-olive">
              Crew Members: {currentUsedSlots}/{currentSlots}
            </p>
          </div>
          {currentSlots < 100 && (
            <div className='buttonContainer '  >
              <button className="w-28" onClick={() => setIsOpen(true)}>Add Slot</button>
            </div>
          )}
        </div>
        {currentUsedSlots < 100 && (
          <AddSlot setIsOpen={setIsOpen} isOpen={isOpen} upgradeSlotsCost={upgradeSlotsCost} ual={ual} />
        )}
        <div className="buttonContainer custom-shadow mr-2 mb-5 box-border h-80 overflow-hidden  overflow-y-auto rounded-md border border-[#2F2F2E] p-6  text-center">
          <div className="flex flex-wrap gap-y-4 ">
            {slots.map((slot) => (
              <Item image={slot.image} isJailed={slot.isJailed} key={slot.id} timeJailed={slot.timeJailed} dirtyCash={slot.dirtyCash} ual={ual} assetId={slot.assetId} cooldown={slot.cooldown} shoot_out={slot.shoot_out} stop_search={slot.stop_search}/>
            ))}
            {currentUsedSlots < 100 && currentSlots > currentUsedSlots && <Add ual={ual} />}
          </div>
        </div>


        <div className="mt-2">
          <p className="text-center  font-bold text-olive">Locked Up</p>

          <div className="mt-3 flex items-center justify-center gap-x-2">
            <div className="w-6">
              <img src={icon1} alt="Solo G" className="object- h-full w-full" />
            </div>
            <p className="text-center text-lg text-white">{total_jail}/{currentUsedSlots}</p>
          </div>
          {total_jail > 0 && (
            <div className="mx-auto mt-2 w-5/12">
              <div
                className="my-5 flex flex-col items-center justify-center rounded-lg border border-[#2F2F2E] py-3 px-3 text-lime-400 hover:bg-[#252525] flex h-24 w-full items-center justify-center rounded-xl"
                onClick={() => setIsOpenPass(true)}
              >
                <FaPlus className="text-5xl text-gray-500 " />
              </div>
              <AddPass isOpen={isOpenPass} setIsOpen={setIsOpenPass} ual={ual} />
            </div>
          )}
        </div>

      </div>
    </div>
  );
};

const Item = ({ image, isJailed, timeJailed, dirtyCash, ual, assetId, cooldown, shoot_out, stop_search }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [remainingTime, setRemainingTime] = useState(
    isJailed ? timeJailed : cooldown // Set initial time based on jail or cooldown
  );

  // Function to format time in HH:MM:SS
  const formatTime = (timeInSeconds) => {
    if (timeInSeconds < 0) {
      return "0:00:00";
    }

    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    if ((isJailed || cooldown > 0) && remainingTime > 0) {
      const intervalId = setInterval(() => {
        setRemainingTime((prevRemainingTime) =>
          prevRemainingTime > 0 ? prevRemainingTime - 1 : 0
        );
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [isJailed, remainingTime, cooldown]);

  const itemStyle = {
    opacity: isJailed ? 0.50 : 1, // Adjust opacity based on the jail status
    position: "relative", // Ensure relative positioning for timer
    width: "50%", // Adjust the width as needed for smaller items
    height: "55%", // Adjust the height as needed for smaller items
  };

  const timerStyle = {
    position: "absolute",
    top: "50%", // Center the timer vertically
    left: "50%", // Center the timer horizontally
    transform: "translate(-50%, -50%)", // Centering transformation
    background: "rgba(0, 0, 0, 0.5)",
    color: "white",
    padding: "4px 8px",
    borderRadius: "4px",
    fontSize: "14px",
  };

  const shootoutStyle = {
    position: "absolute",
    top: "75%", // Center the timer vertically
    left: "20%", // Center the timer horizontally
    transform: "translate(-50%, -50%)",
    color: "white",
    padding: "4px 8px",
    borderRadius: "4px",
    fontSize: "14px",
    whiteSpace: "nowrap", // Prevent text wrapping
  };

  const stopSearchStyle = {
    position: "absolute",
    top: "75%", // Center the timer vertically
    left: "80%", // Center the timer horizontally
    transform: "translate(-50%, -50%)",
    color: "white",
    padding: "4px 8px",
    borderRadius: "4px",
    fontSize: "14px",
    whiteSpace: "nowrap", // Prevent text wrapping
  };

  return (
    <div
      className="item-container"
      onClick={() => setIsOpen(true)}
      style={itemStyle}
    >
      <div className="h-full w-full px-2">
        <img
          src={image}
          alt="Item Icon"
          className="h-full w-full object-cover"
        />
      </div>

      {isJailed && (
        <div className="cooldown-timer" style={timerStyle}>
          In Jail: {formatTime(remainingTime)}
        </div>
      )}

      <div className="police_shoot_out" style={shootoutStyle}>
        PS: {shoot_out}
      </div>

      <div className="stop_search" style={stopSearchStyle}>
        SS: {stop_search}
      </div>

      {cooldown > 0 && !isJailed && (
        <div className="cooldown-timer" style={timerStyle}>
          Upgrade cooldown: {formatTime(remainingTime)}
        </div>
      )}

      {!isJailed && cooldown <= 0 && (
        <div className="cooldown-timer" style={timerStyle}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginLeft: "8px" }}>{dirtyCash}</span>
            <img
              src={DirtyCashTokenIcon}
              alt="Item Icon"
              className="object-cover"
              width={"35%"}
            />
          </div>
        </div>
      )}

      <ModalMenu isOpen={isOpen} setIsOpen={setIsOpen} ual={ual} asset_id={assetId} />
    </div>
  );
};


const Add = ({ ual }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="w-1/2 ">
      <div className=" m-2 w-full">
        <div
          className="flex h-40 w-full cursor-pointer items-center justify-center  rounded-xl border border-[#2F2F2E] "
          onClick={() => setIsOpen(true)}
        >
          <FaPlus className="text-5xl text-gray-500" />
        </div>
        <AddCharacter setIsOpen={setIsOpen} isOpen={isOpen} ual={ual} />
      </div>
    </div>
  );
};

export default LeftContent;
