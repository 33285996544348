import React, { useMemo, useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import DirtyCashIcon from "assets/images/icons/dirty-cash-token.png";
import icon1 from "assets/images/icons/wallet.png";
import icon3 from "assets/images/icons/work.png";
import Modal from "components/shared/Modal";
import { useDispatch, useSelector } from "react-redux";

import { anchorGetBalance, reUp, refreshAll } from "BlockchainInteractionWax";

const RATE = 184.808723;

const ReUpForm = ({ isOpen, setIsOpen, ual }) => {
  const [amount] = useState(10);
  const [amountInWallet, setAmountInWallet] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const store = useSelector((state) => state);
  const dispatch = useDispatch();

  const fetchData = async () => {
    if (ual.activeUser) {
      const values = await anchorGetBalance(ual);
      setAmountInWallet(parseFloat(values[1]).toFixed(3));
    }
  };

  useEffect(() => {
    fetchData();
  }, [store]);

  async function handleReUpClick() {
    // Call the function you want to execute on click here
    setIsLoading(true);
    await reUp(ual, parseFloat(input));
    refreshAll(dispatch)
    setIsLoading(false);
    setIsOpen(false);
  }

  const [input, setInput] = useState("");

  const exchangedAmount = useMemo(() => {
    if (!input) return "";
    const result = parseFloat(input) * RATE;
    return result.toLocaleString(); // add toLocaleString() here
  }, [input]);

  return (
    <Modal
      isOpen={isOpen}
    >
      <div
        className="w-full rounded-xl py-4 px-6 font-Montserrat py-4 px-6"
        style={{ backgroundImage: "radial-gradient(#292929, #000000)", border: "6px outset rgba(165, 165, 165, 0.4)" }}
      >
        <form>
          <div className="mb-2 flex items-center justify-between">
            <div />
            <h2 className="px-5 py-2 text-center text-2xl font-bold text-white">
              Re-up
            </h2>
            <FaTimes
              className="block text-2xl text-white hover:cursor-pointer"
              onClick={() => setIsOpen(false)}
            />
          </div>
          <div className="text-center text-white">
            <p>Exchange Rate</p>
            <p>
              {(9.99999999735 / RATE).toFixed(5)} Dirty Cash = {amount} Work
            </p>
          </div>
          <div>
            <div className=" flex items-center justify-end gap-x-2 text-white">
              <p className="mt-2">{amountInWallet}</p>
              <div className="h-10 w-14">
                <img
                  src={icon1}
                  alt="Wallet Icon"
                  className="object- h-full w-full"
                />
              </div>
            </div>
            <div className="flex items-center justify-between rounded-md  bg-[#252525] py-2 px-1.5">
              <input
                type="text"
                name=""
                className=" flex-1 border-none bg-transparent pl-4 text-white outline-none"
                placeholder="insert amount"
                id=""
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
              <p className="w-10 pl-2.5 ">
                <img
                  src={DirtyCashIcon}
                  alt="Dirty Cash Icon"
                  className="mx-auto w-10"
                />
              </p>
            </div>
          </div>
          <div className="mt-4">
            <div className="text-left text-white ">
              <p>You will receive:</p>
            </div>
            <div className="flex items-center justify-between rounded-md bg-[#252525] py-2 px-1.5">
              <input
                type="text"
                name=""
                className="flex-1 border-none bg-transparent  text-white pl-4 outline-none"
                placeholder="receive amount"
                readOnly
                value={exchangedAmount}
              />
              <p className="w-10 rounded-md  pl-2.5 ">
                <img
                  src={icon3}
                  alt="Works Icon"
                  className="mx-auto w-10"
                />
              </p>
            </div>
          </div>

          <div className="mt-5 flex justify-center">
            <div className='buttonContainer my-5'  >
              <button className="w-40 px-4 py-1 " onClick={handleReUpClick} disabled={isLoading}>Re-up</button>
            </div>
            
            {/* <button className="my-5 flex flex-col items-center justify-center rounded-lg border border-[#2F2F2E] py-3 px-3 text-lime-400 hover:bg-[#252525] w-40" onClick={handleReUpClick} disabled={isLoading}>
              Re-up
            </button> */}
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ReUpForm;
