import { FaPlus } from "react-icons/fa";

import React, { useState, useEffect, useCallback, useMemo, Component } from "react";
import { refreshAll, anchorGetGRank, isSafeBoosterStacked, getLastActionMessage } from "BlockchainInteractionWax";
import { useDispatch, useSelector } from "react-redux";
import AddBoost from "./AddBoost";
import AddCharacter from "./AddCharacter";

const Booster = ({ ual }) => {

  const [isBoostOpen, setIsBoostOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isCharacterOpen, setIsCharacterOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [assetId, setAssedId] = useState(0)
  const [gRank, setGRank] = useState(0)
  const [description, setDescription] = useState("")
  const [popUpMessage, setPopUpMessage] = useState("");

  const store = useSelector((state) => state);
  const dispatch = useDispatch();

  async function handleGetReport()
  {
    const value7 = await getLastActionMessage(ual);
    setPopUpMessage(value7)
    setIsPopupOpen(true);
  }

  useEffect(() => {
    const fetchData = async () => {
      if (ual.activeUser !== undefined) {
        const data = await isSafeBoosterStacked(ual);
        setImageSrc(data[0])
        setDescription(data[2])
        const rank = await anchorGetGRank(ual)
        setGRank(rank)
      }
    };
    fetchData();
  }, [store, ual]);

  async function handleClick()
  {
    if(imageSrc === "")
    {
      setIsBoostOpen(true)
      await refreshAll(dispatch);
    }
  };

  async function UnstakeClick()
  {

    await refreshAll(dispatch);
  }

  return (
    <div className="mx-4 box-border pb-4">
    {gRank > 1 && (     
      <div className="custom-shadow mr-2 box-border h-80 overflow-hidden rounded-md border border-[#2F2F2E] p-6 text-center">
        <div
          className="relative h-full w-full cursor-pointer items-center justify-center rounded-xl px-2"
          onClick={handleClick}
        >
          {imageSrc !== "" ? (
            <img
              src={imageSrc}
              alt="your-image-description"
              className="max-w-full max-h-full mx-auto"
            />
          ) : (
            <div className="flex h-full w-full items-center justify-center">
              <FaPlus className="text-5xl text-gray-500" />
            </div>
          )}
        </div>
      </div>
      )}
      <AddBoost setIsOpen={setIsBoostOpen} isOpen={isBoostOpen} ual={ual} />
      <AddCharacter setIsOpen={setIsCharacterOpen} isOpen={isCharacterOpen} ual={ual} slot={1}/>
      <div className="mt-2">   
      {imageSrc !== "" && (       
          <div className="text-center text-white text-2xl mt-5 sm:mt-0">
          <p>{description}</p>
      </div>
          )}
          {isPopupOpen && (
          <div className="flex flex-col items-center w-full bg-[#1a1b1f] border border-solid justify-center border-gray-500 rounded-xl py-4 px-6"
            style={{ backgroundImage: "radial-gradient(#292929, #000000)", border: "6px outset rgba(165, 165, 165, 0.4)" }}>
            <p className="text-white text-md font-semibold mb-4" style={{ textAlign: 'center' }}>
              {popUpMessage}
            </p>
            <div className='buttonContainer'  >
              <button className="w-full px-4 py-1" onClick={() => setIsPopupOpen(false)}>Ok</button>
            </div>
          </div>
          )}
            <div className="mt-5 flex items-center justify-center">
              <div className="w-full">
                <div className='buttonContainer '  >
                  <button className="w-full px-4 py-1" onClick={handleGetReport}>Get Report</button>
                </div>
              </div>
            </div>
      </div>       
    </div>
  );
};

export default Booster;
