import React, { useState, useEffect, useCallback } from "react";
import { FaTimes } from "react-icons/fa";
import Modal from "components/shared/Modal";

import { useDispatch, useSelector } from "react-redux";
import { anchorStakeDirtyLaundering, refreshAll } from "BlockchainInteractionWax.js"

const StakeConfirmation = ({ isOpen, setIsOpen, setIsOpenStakeLaundering, dirtyCashAmount, ual }) => {

    const store = useSelector((state) => state);
    const dispatch = useDispatch();

    async function handleStakeClick() {
        await anchorStakeDirtyLaundering(ual, dirtyCashAmount)
        await new Promise(resolve => setTimeout(resolve, 500));
        await refreshAll(dispatch)
        setIsOpen(false);
        setIsOpenStakeLaundering(false)
    }
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
        //   className='w-8/12'
        >
            <div
                className="w-full rounded-xl py-4 px-6 font-Montserrat py-4 px-6"
                style={{ backgroundImage: "radial-gradient(#292929, #000000)", border: "6px outset rgba(165, 165, 165, 0.4)" }}
            >
                <>
                    <div className="mb-2 flex items-center justify-between">
                        <h2 className="px-5 py-2 text-center font-Montserrat text-2xl text-olive">
                            Make sure you collect any laundered cash you have before staking. Failure to do so will result in loss of current progress
                        </h2>

                    </div>

                    <div className="flex justify-center gap-2">
                        <button className="rounded-lg border border-[#2F2F2E] py-3 px-3 text-lime-400 hover:bg-[#252525] my-5 w-28"
                            onClick={(e) => { e.preventDefault(); handleStakeClick() }}>Yes</button>
                        <button
                            className="rounded-lg border border-[#2F2F2E] py-3 px-3 text-lime-400 hover:bg-[#252525] my-5 w-28"
                            onClick={() => setIsOpen(false)}            >              No            </button>
                    </div>
                </>
            </div>
        </Modal>
    );
};

export default StakeConfirmation;
