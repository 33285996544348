import React, { useState, useEffect, useCallback } from 'react';
import { FaInfoCircle } from "react-icons/fa";
import Tippy from "@tippyjs/react";
import { useSelector, useDispatch } from "react-redux";
import LeaderBoardImg from "assets/images/leaderboard/hustlersclub.png";
import "App.css"

import {
  anchorGetMLCleanCashe,
  anchorGetTrapHouseUnlock,
  anchorGetTrophiesData,
  anchorGetMPower,
  claimTrophy,
  anchorGetLocationCrew,
  isConsignmentStaked,
  anchorGetLabUnlock,
  refreshAll
} from "BlockchainInteractionWax";
//trophy icons
import Rookie_Hustler from "assets/images/Trophies  High Resolution/Rookie Hustler.png";
import Crew_Builder from "assets/images/Trophies  High Resolution/Crew Builder.png";
import Laundering_Apprentice from "assets/images/Trophies  High Resolution/Laundering Apprentice.png";
import Trap_King from "assets/images/Trophies  High Resolution/Trap King.png";
import Vault_Vindicator from "assets/images/Trophies  High Resolution/Vault Vindicator.png";
import Exchange_Enthusiast from "assets/images/Trophies  High Resolution/Exchange Enthusiast.png";
import Criminal_Cashflow from "assets/images/Trophies  High Resolution/Criminal Cashflow.png";
import Grand_Larceny_Legends from "assets/images/Trophies  High Resolution/Grand Larceny Legends.png";
import Entrepreneurial_Empire from "assets/images/Trophies  High Resolution/Entrepreneurial Empire.png";
import OG from "assets/images/Trophies  High Resolution/OG.png";
import DPS_Unleashed from "assets/images/Trophies  High Resolution/DPS Unleashed.png";
import The_Pusher from "assets/images/Trophies  High Resolution/The Pusher.png";
import The_Collector from "assets/images/Trophies  High Resolution/The Collector.png";
import Laundering_Master from "assets/images/Trophies  High Resolution/Laundering Master.png";
import Lab_Liberator from "assets/images/Trophies  High Resolution/Lab Liberator.png";
import Raid_Rampage from "assets/images/Trophies  High Resolution/Raid Rampage.png";
import Swap_Sovereign from "assets/images/Trophies  High Resolution/Swap Sovereign.png";
import Underworld_Wealth from "assets/images/Trophies  High Resolution/Underworld Wealth.png";
import A_Dogs_Day from "assets/images/Trophies  High Resolution/A Dogs Day.png";
import Tycoon_Triumph from "assets/images/Trophies  High Resolution/Tycoon Triumph.png";
import Tripple_OG from "assets/images/Trophies  High Resolution/Tripple OG.png";
import DPS_Dynasty from "assets/images/Trophies  High Resolution/DPS Dynasty.png";
import Rogue_Riches from "assets/images/Trophies  High Resolution/Rogue Riches.png";
import Mob_Magnate from "assets/images/Trophies  High Resolution/Mob Magnate.png";
import Mafioso from "assets/images/Trophies  High Resolution/Mafioso.png";
import Safe_Vandal from "assets/images/Trophies  High Resolution/Safe Vandal.png";
import Kingpin_Kiosk from "assets/images/Trophies  High Resolution/Kingpin Kiosk.png";
import Gangland_Grind from "assets/images/Trophies  High Resolution/Gangland Grind.png";
import Heist_Haul_Honcho from "assets/images/Trophies  High Resolution/Heist Haul Honcho.png";
import Quadruple_OG from "assets/images/Trophies  High Resolution/Quadruple OG.png";
import Hustlers_Castle from "assets/images/Trophies  High Resolution/Hustlers Castle.png";
import Welcome_to_the_club from "assets/images/Trophies  High Resolution/Welcome to the club.png";
import Estate_Apex from "assets/images/Trophies  High Resolution/Estate Apex.png";
import Grand_Token_Triumph from "assets/images/Trophies  High Resolution/Grand Token Triumph.png";
import DPS_Overdrive from "assets/images/Trophies  High Resolution/DPS Overdrive.png";
import Baller from "assets/images/Trophies  High Resolution/Baller.png";

import { RingLoader } from 'react-spinners';

const Challenge = ({ ual }) => {

  const [missionList, setMissionList] = useState([
    { id: 1, name: "Rookie Hustler", task: "Get 100K successful collections", status: 0, isClaimed: false, trophy: Rookie_Hustler },
    { id: 2, name: "Crew Builder", task: "Recruit 50 crew members", status: 0, isClaimed: false, trophy: Crew_Builder },
    { id: 3, name: "Laundering Apprentice", task: "Successfully launder 100K Dirty Cash", status: 0, isClaimed: false, trophy: Laundering_Apprentice },
    { id: 4, name: "Trap King", task: "Unlock the traphouse", status: 0, isClaimed: false, trophy: Trap_King },
    // { id: 5, name: "Vault Vindicator", task: "Perform 10K successful Safe Raids", status: 0, isClaimed: false, trophy: Vault_Vindicator },
    { id: 6, name: "Exchange Enthusiast", task: "Use the exchange 25 times", status: 0, isClaimed: false, trophy: Exchange_Enthusiast },
    // { id: 7, name: "Criminal Cashflow", task: "Earn 250K dirty cash in Turf War", status: 0, isClaimed: false, trophy: Criminal_Cashflow },
    // { id: 8, name: "Grand Larceny Legends", task: "Complete 50 Heists", status: 0, isClaimed: false, trophy: Grand_Larceny_Legends },
    // { id: 9, name: "Entrepreneurial Empire", task: "GeOwn a business", status: 0, isClaimed: false, trophy: Entrepreneurial_Empire },
    { id: 10, name: "OG", task: "Reach G Rank level 10K", status: 0, isClaimed: false, trophy: OG },
    { id: 11, name: "DPS Unleashed", task: "Reach 1 DPS Hustling power", status: 0, isClaimed: false, trophy: DPS_Unleashed },
    { id: 12, name: "The Pusher", task: "Get 500K successful collections", status: 0, isClaimed: false, trophy: The_Pusher },
    { id: 13, name: "The Collector", task: "Recruit 100 crew members", status: 0, isClaimed: false, trophy: The_Collector },
    { id: 14, name: "Laundering Master", task: "Successfully launder 500K Dirty Cash", status: 0, isClaimed: false, trophy: Laundering_Master },
    { id: 15, name: "Lab Liberator", task: "Unlock the clandestine lab", status: 0, isClaimed: false, trophy: Lab_Liberator },
    // { id: 16, name: "Raid Rampage", task: "Perform 50K successful Safe Raids", status: 0, isClaimed: false, trophy: Raid_Rampage },
    { id: 17, name: "Swap Sovereign", task: "Use the exchange 75 times", status: 0, isClaimed: false, trophy: Swap_Sovereign },
    // { id: 18, name: "Underworld Wealth", task: "Earn 500K dirty cash in Turf War", status: 0, isClaimed: false, trophy: Underworld_Wealth },
    // { id: 19, name: "A Dogs Day", task: "Complete 100 Heists ", status: 0, isClaimed: false, trophy: A_Dogs_Day },
    // { id: 20, name: "Tycoon Triumph", task: "Own 2 business", status: 0, isClaimed: false, trophy: Tycoon_Triumph },
    { id: 21, name: "Tripple OG", task: "Reach G Rank level 50K", status: 0, isClaimed: false, trophy: Tripple_OG },
    { id: 22, name: "DPS Dynasty", task: "Reach 2.5 DPS hustling power", status: 0, isClaimed: false, trophy: DPS_Dynasty },
    { id: 23, name: "Rogue's Riches", task: "Complete 1 million successful collections", status: 0, isClaimed: false, trophy: Rogue_Riches },
    { id: 24, name: "Mob Magnate", task: "Recruit 250 crew members", status: 0, isClaimed: false, trophy: Mob_Magnate },
    { id: 25, name: "Mafioso", task: "Successfully launder 1 million Dirty Cash", status: 0, isClaimed: false, trophy: Mafioso },
    // { id: 26, name: "Safe Vandal", task: " Perform 100K successful Safe Raids", status: 0, isClaimed: false, trophy: Safe_Vandal },
    { id: 27, name: "Kingpin's Kiosk", task: "Use the exchange 125 times", status: 0, isClaimed: false, trophy: Kingpin_Kiosk },
    // { id: 28, name: "Gangland Grind", task: "Earn 1 million dirty cash in Turf War", status: 0, isClaimed: false, trophy: Gangland_Grind },
    // { id: 29, name: "Heist Haul Honcho", task: "Complete 200 Heists", status: 0, isClaimed: false, trophy: Heist_Haul_Honcho },
    { id: 30, name: "Quadruple OG", task: "Reach G Rank level 100K", status: 0, isClaimed: false, trophy: Quadruple_OG },
    // { id: 31, name: "Hustlers Castle", task: " Own a property", status: 0, isClaimed: false, trophy: Hustlers_Castle },
    // { id: 32, name: "Welcome to the club", task: "Spend 500K  in subscription fees", status: 0, isClaimed: false, trophy: Welcome_to_the_club },
    // { id: 33, name: "Estate Apex", task: " Collect 10K career tokens", status: 0, isClaimed: false, trophy: Estate_Apex },
    // { id: 34, name: "Grand Token Triumph", task: " Collect 10K career tokens", status: 0, isClaimed: false, trophy: Grand_Token_Triumph },
    { id: 35, name: "DPS Overdrive", task: " Reach 5 DPS hustling power", status: 0, isClaimed: false, trophy: DPS_Overdrive },
    { id: 36, name: "Baller", task: "Spend 1 million Clean Cash in the shops", status: 0, isClaimed: false, trophy: Baller },

  ]);

  const [earned, setEarned] = useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [radius, setRadius] = useState(0)
  const [stroke, setStroke] = useState(0)
  const [normalizedRadius, setNormalizedRadius] = useState(0)
  const [circumference, setCircumference] = useState(0)
  const [percentage, setPercentage] = useState(0)
  const [strokeDashoffset, setStrokeDashoffset] = useState(0)

  const calculateStrokeDashoffset = (percentage, circumference) => {
    return circumference - (percentage / 100) * circumference;
  };
  
  function CircularProgress(earned ) {
    setRadius(54);
    setStroke(4);
    setNormalizedRadius(54 - 4 * 2);
    const normalizedRad = 54 - 4 * 2
    setCircumference(normalizedRad * 2 * Math.PI);
    const circ = normalizedRad * 2 * Math.PI
    setPercentage(parseFloat((earned * 100 / missionList.length).toFixed(1)));
    setStrokeDashoffset(calculateStrokeDashoffset(parseFloat((earned * 100 / missionList.length)), circ));
  }

  const store = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();    
  }, [ual, store]);

  async function handleClaim(id){
    await claimTrophy(ual, id);
    await refreshAll(dispatch)
  }

  const fetchData = useCallback(async () => {
    if (ual.activeUser !== undefined) {
      setIsLoading(true);
      
      const data = await anchorGetTrophiesData(ual)
      const LCleanCashe = await anchorGetMLCleanCashe(ual);
      const traphouse_unlocked = await anchorGetTrapHouseUnlock(ual);
      const lab_unlocked = await anchorGetLabUnlock(ual)
      const grank_lvl = data.g_level
      const MiningP_Rank = await anchorGetMPower(ual, "", 0);
      const drops = data.successful_drops
      const exchanges_total = data.total_exchanges
      const spender = data.total_cc_spent	
      var crew = await anchorGetLocationCrew(ual, 0) + await anchorGetLocationCrew(ual, 1) + await anchorGetLocationCrew(ual, 2)
      const value = await isConsignmentStaked(ual);
      if(value[0] != "")
      {
        crew++
      }
      var total_earned = 0
      data.trophies.forEach((trophy, index) => {
        setMissionList(prevMissions => {
          return prevMissions.map(m => {
            if (m.id === trophy) {
              return { ...m, isClaimed: true };
            }
            return m;
          });
        });
      });

      data.trophies.forEach((trophy, index) => {
        total_earned++
      });

      setEarned(total_earned)

      CircularProgress(total_earned)
      
      setIsLoading(false);

      setMissionList(prevMissions => {
        return prevMissions.map(m => {
          if (m.id === 1) {
            return { ...m, status: Math.min(parseFloat((drops * 100) / 100000).toFixed(2), 100) };
          }
          return m;
        });
      });

      setMissionList(prevMissions => {
        return prevMissions.map(m => {
          if (m.id === 2) {
            return { ...m, status: Math.min(parseFloat(crew * 100 / 50).toFixed(2), 100) };
          }
          return m;
        });
      });

      setMissionList(prevMissions => {
        return prevMissions.map(m => {
          if (m.id === 3) {
            return { ...m, status: Math.min(parseFloat(LCleanCashe * 100 / 100000).toFixed(2), 100) };
          }
          return m;
        });
      });
      setMissionList(prevMissions => {
        return prevMissions.map(m => {
          if (m.id === 4) {
            return { ...m, status: traphouse_unlocked === 1 ? 100 : 0 };
          }
          return m;
        });
      });
      setMissionList(prevMissions => {
        return prevMissions.map(m => {
          if (m.id === 6) {
            return { ...m, status: Math.min(parseFloat(exchanges_total * 100 / 25).toFixed(2), 100) };
          }
          return m;
        });
      });

      setMissionList(prevMissions => {
        return prevMissions.map(m => {
          if (m.id === 10) {
            return { ...m, status: Math.min(parseFloat(grank_lvl * 100 / 10000).toFixed(2), 100) };
          }
          return m;
        });
      });

      setMissionList(prevMissions => {
        return prevMissions.map(m => {
          if (m.id === 11) {
            return { ...m, status: Math.min(parseFloat(parseFloat(MiningP_Rank).toFixed(6) * 100 / 1).toFixed(2), 100) };
          }
          return m;
        });
      });

      setMissionList(prevMissions => {
        return prevMissions.map(m => {
          if (m.id === 12) {
            return { ...m, status: Math.min(parseFloat(drops * 100 / 500000).toFixed(2), 100) };
          }
          return m;
        });
      });

      setMissionList(prevMissions => {
        return prevMissions.map(m => {
          if (m.id === 13) {
            return { ...m, status: Math.min(parseFloat(crew * 100 / 100).toFixed(2), 100) };
          }
          return m;
        });
      });

      setMissionList(prevMissions => {
        return prevMissions.map(m => {
          if (m.id === 14) {
            return { ...m, status: Math.min(parseFloat(LCleanCashe * 100 / 500000).toFixed(2), 100) };
          }
          return m;
        });
      });

      setMissionList(prevMissions => {
        return prevMissions.map(m => {
          if (m.id === 15) {
            return { ...m, status: lab_unlocked === 1 ? 100 : 0 };
          }
          return m;
        });
      });

      setMissionList(prevMissions => {
        return prevMissions.map(m => {
          if (m.id === 17) {
            return { ...m, status: Math.min(parseFloat(exchanges_total * 100 / 75).toFixed(2), 100) };
          }
          return m;
        });
      });

      setMissionList(prevMissions => {
        return prevMissions.map(m => {
          if (m.id === 21) {
            return { ...m, status: Math.min(parseFloat(grank_lvl * 100 / 50000).toFixed(2), 0) };
          }
          return m;
        });
      });

      setMissionList(prevMissions => {
        return prevMissions.map(m => {
          if (m.id === 22) {
            return { ...m, status: Math.min(parseFloat(parseFloat(MiningP_Rank) * 100 / 2.5).toFixed(2), 100) };
          }
          return m;
        });
      });

      setMissionList(prevMissions => {
        return prevMissions.map(m => {
          if (m.id === 23) {
            return { ...m, status: Math.min(parseFloat(drops * 100 / 1000000).toFixed(2), 100) };
          }
          return m;
        });
      });

      setMissionList(prevMissions => {
        return prevMissions.map(m => {
          if (m.id === 24) {
            return { ...m, status: Math.min(parseFloat(crew * 100 / 250).toFixed(2), 100) };
          }
          return m;
        });
      });

      setMissionList(prevMissions => {
        return prevMissions.map(m => {
          if (m.id === 25) {
            return { ...m, status: Math.min(parseFloat(LCleanCashe * 100 / 1000000).toFixed(2), 100) };
          }
          return m;
        });
      });

      setMissionList(prevMissions => {
        return prevMissions.map(m => {
          if (m.id === 27) {
            return { ...m, status: Math.min(parseFloat(exchanges_total * 100 / 125).toFixed(2), 100) };
          }
          return m;
        });
      });

      setMissionList(prevMissions => {
        return prevMissions.map(m => {
          if (m.id === 30) {
            return { ...m, status: Math.min(parseFloat(grank_lvl * 100 / 100000).toFixed(2), 100) };
          }
          return m;
        });
      });

      setMissionList(prevMissions => {
        return prevMissions.map(m => {
          if (m.id === 35) {
            return { ...m, status: Math.min(parseFloat(parseFloat(MiningP_Rank) * 100 / 5).toFixed(2), 100) };
          }
          return m;
        });
      });

      setMissionList(prevMissions => {
        return prevMissions.map(m => {
          if (m.id === 36) {
            return { ...m, status: Math.min(parseFloat(parseFloat(spender) * 100 / 1000000).toFixed(2), 100) };
          }
          return m;
        });
      });
    }
  }, [ual, store])

  return (

    <div className="flex w-full flex-col items-center justify-center min-h-screen">
      {isLoading ? (
        <RingLoader color="#36D7B7" loading={isLoading} size={150}  />
      ) : (
        <>
          <div className="flex w-full flex-col items-center justify-center pb-2">
            <img src={LeaderBoardImg} width="300" height="200"  alt="" />
        <div >

            <InfoIcon  />
        </div>
            <div className="w-full px-10 flex justify-center">
              <div className="w-full px-10 sm:flex-col md:flex-row">
                <div className="overflow-x-auto">
                  <div className="mt-5 flex flex-col sm:flex-row justify-center items-center rounded-3xl text-md border border-[#2F2F2E] lg:h-[200px]  align-bottom text-md xl:text-md mx-auto lg:w-1/2">
                    <div className="text-center text-white text-2xl">
                      <p>{missionList.length}</p>
                      <p>Available</p>
                    </div>
                    <div className="circle-progress-container" style={{ position: 'relative', width: '108px', height: '108px' }}>
                      <svg
                        height={radius * 2}
                        width={radius * 2}
                      >
                        <circle
                          stroke="green"
                          fill="transparent"
                          strokeWidth={stroke}
                          strokeDasharray={circumference + ' ' + circumference}
                          style={{ strokeDashoffset, transition: 'stroke-dashoffset 0.5s ease 0s', transform: 'rotate(-90deg)', transformOrigin: 'center' }}
                          r={normalizedRadius}
                          cx={radius}
                          cy={radius}
                        />
                      </svg>
                      <div className="circle-progress-percentage" style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontSize: '2rem',
                        color: 'white'
                      }}>
                        {percentage}%
                      </div>
                    </div>
                    <div className="text-center text-white text-2xl mt-5 sm:mt-0">
                      <p>{earned}</p>
                      <p>Earned</p>
                    </div>
                  </div>
                  <div
                    className="relative w-full overflow-x-auto"
                  >
                    <table className="mt-2 table w-full text-left  text-white dark:text-gray-400 ">
                      <thead className="border-t border-[#252525] uppercase text-white">
                        <tr>
                          <th scope="col" className="w-1/12 py-3 px-6 text-center">
                            <p className="font-bold">Trophies</p>
                          </th>
                          <th scope="col" className="w-1/12 py-3 px-6 text-center">
                            <p className="font-bold">Missions</p>
                          </th>
                          <th scope="col" className="w-1/12 py-3 px-6 text-center">
                            <p className="font-bold">Status</p>
                          </th>
                          <th scope="col" className="w-1/12 py-3 px-6 text-center">
                            <p className="font-bold">Trophy Claim</p>
                          </th>
                        </tr>

                      </thead>
                      <tbody>
                        {missionList.map((mission, index) => {
                          return (
                            <tr className="w-full border-b border-t border-[#252525] odd:bg-[#151514]">

                              <td className="text-center whitespace-nowrap py-3 px-6 font-medium text-gray-900 dark:text-white">
                                <button className="w-auto mt-2 ml-[calc(37vw-30px)] md:ml-0 text-lime-400">
                                  <img
                                    src={mission.trophy}
                                    className="w-12 h-12 rounded-full cursor-pointer"
                                  />
                                </button>
                              </td>
                              <td className="text-center py-3 px-6 font-medium text-gray-900 dark:text-white">
                                <p className="mt-2 w-full rounded-lg border border-[#2F2F2E] py-2 text-white text-center">
                                  {mission.name}: {mission.task}
                                </p>
                              </td><td className="text-center whitespace-nowrap py-3 px-6 font-medium text-gray-900 dark:text-white">
                                <p className={`mt-2 w-full rounded-lg border border-[#2F2F2E] py-2 text-white text-center ${mission.status === 100 ? 'text-green-500' : ''}`}>
                                  {mission.status} %
                                </p>

                              </td><td className="text-center whitespace-nowrap py-3 px-6 font-medium text-gray-900 dark:text-white">
                                <button
                                  disabled={mission.status !== 100 || mission.isClaimed === true}
                                  className={`text-center mt-2 w-full rounded-lg border border-[#2F2F2E] py-2 w-70 bg-transparent ${(mission.status === 100 && mission.isClaimed === false)? 'text-lime-400 hover:bg-[#252525]' : 'text-gray-500'}`}
                                  onClick={() => handleClaim(mission.id)}
                                >
                                  {(mission.isClaimed === false)? 'Claim' : 'Claimed'}
                                </button>

                              </td>
                            </tr>
                          )

                        })}
                      </tbody>
                    </table >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>

  );
};
const InfoIcon = () => (
  <Tippy
    content={
      <span>
        Complete challenges in order to claim trophies.
      </span>
    }
    
    popperOptions={{
      placement: "center",
    }}
  >
    <p className="text-xl text-white">
      <FaInfoCircle />
    </p>
  </Tippy>
);

export default Challenge;
